.footer{
    background-color: #d9d9d932;
    min-height: 4vh;
}

.footer .container {
    display: flex;
    margin: 4rem auto;
    margin-bottom: 0;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    overflow: hidden;
}

.social-icons{
    display: flex;
    gap: 15px;
}


@media screen and (max-width: 940px) {
    .footer .container {
        flex-direction: column;
        gap: 20px
    }
}










