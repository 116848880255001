.faqs .container {
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 4rem auto;
    justify-content: space-between;
    padding: 0.5rem;
    overflow: hidden;
}

.faq-head{
    color: #4F4F4F;
    padding-bottom: 25px; 
}




